import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';

export const pageQuery = graphql`
  query PoliciesPageQuery($locale: String!) {
    settings: contentfulHomepageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords {
        keywords
      }
    }

    allMdx {
      nodes {
        id
        excerpt
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;

const PoliciesPage = ({ data }) => {
  const { nodes: policies } = data.allMdx;
  const { settings } = data;

  return (
    <Layout>
      <SEO
        title={settings.title}
        keywords={settings.keywords.keywords.split(',')}
        description={settings.description.description}
      />
      <div className="text-xl max-w-xl m-auto leading-normal">
        <h1 className="text-5xl">Policies.</h1>
        <p className="text-2xl mt-2 mb-8">
          This is the section of the site where the boring things are listed. But rest assured that
          there is little to no asterisk-marked, fine-print, in here.
        </p>
        <ul className="list-reset">
          {policies.map(policy => (
            <li key={policy.id}>
              <Link className="text-3xl font-bold text-blue inline-block" to={policy.fields.slug}>
                {policy.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default PoliciesPage;
